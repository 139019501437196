<template>
    <div class="container">

        <div class="text-center mt-4 mb-4">
            <h4>Выбор группы</h4>
        </div>
        <div v-if="loadingPage" class="d-flex justify-content-center mt-5 mb-5">
            <Preloader/>
        </div>
        <div v-else>
            <div v-if="selectableDisciplines_form.selectableStudentGroupsByStudentDataId != null && selectableDisciplines_form.selectableStudentGroupsByStudentDataId != false"
                 class="mt-4">
                <p>
                    Выбранная группа: <strong>{{selectableDisciplines_form.selectableStudentGroupsByStudentDataId.selectable_groups_name}}</strong>
                </p>
                <p>
                    Уровень обучения: <strong>{{selectableDisciplines_form.selectableStudentGroupsByStudentDataId.language_level_name}}</strong>
                </p>

                <p>
                    Преподаватель: <strong>{{selectableDisciplines_form.selectableStudentGroupsByStudentDataId.pps_data_lastname}}
                    {{selectableDisciplines_form.selectableStudentGroupsByStudentDataId.pps_data_firstname}}</strong>
                </p>
            </div>

            <div v-if="selectableDisciplines_form.selectableStudentGroupsByStudentDataId == false">
                <div class="form-group row mt-4">
                    <label class="col-md-3 col-form-label">1. Выберите поток</label>
                    <div class="col-md-9">
                        <select class="form-control form-select"
                                @input="changeSelectableFlowId($event)">
                            <option v-for="(item, index) in [{id: 0, selectable_flow_name: 'Выберите поток'}, ...selectableDisciplines_form.flows]"
                                    :value="item.selectable_flow_id"
                                    :key="index">{{ item.selectable_flow_name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-12">
                        <button type="button" class="btn btn-primary" @click="selectFlow">Выбрать</button>
                    </div>
                </div>

                <div v-if="isFlowSelected">
                    <div class="row border m-2 mt-4 mb-4 rounded"
                         v-for="(item, index) in selectableDisciplines_form.flows.filter(i => i.selectable_flow_id == selectable_flow_id)"
                         :key="index">
                        <div class="col col-md-2 p-2">
                            <h5 class="p-3">{{item?.selectable_flow_name}}</h5>
                        </div>
                        <div class="col-md-10">
                            <div class="row p-2">
                                <div class="col col-md-3 p-2" v-for="(group, groupIndex) in item.groups"
                                     :key="groupIndex">
                                    <div>
                                        <div>
                                    <span>
                                        <b style="margin-right: 11px">{{group?.name || 'Нет названия группы'}}</b>
                                      <input class="form-check-input" type="radio" name="selectable_group_id"
                                             v-model="selectedGroup"
                                             :value="group.selectable_group_id">
                                    </span>&nbsp;
                                        </div>
                                        <div>
                                            {{group?.language_level_name}}
                                        </div>
                                        <div>{{group?.pps_data_lastname+' '+group?.pps_data_firstname}}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center mt-2 mb-3">
                        <button type="button" class="btn btn-primary m-1" @click="postSelectableGroupStudents"
                                :disabled="!selectedGroup||postSelectableGroupStudentsButton">
                            <!--          v-if="selectableDisciplines_form.selectableStudentGroupsByStudentDataId == null"-->
                            Сохранить
                        </button>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>

    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "SelectableGroupsForDiscipline",
        data() {
            return {
                education_discipline_id: +this.$route.query.education_discipline_id || 0,
                selectable_flow_id: null,
                isFlowSelected: false,
                postSelectableGroupStudentsButton: false,
                selectedGroup: null,
                loadingPage: true

            }
        },
        computed: {
            ...mapState('selectableDisciplines', ['selectableDisciplines_form'])
        },

        methods: {
            ...mapActions('selectableDisciplines', ['GET_EDUCATION_COURSES_BY_STUDENT_ID', 'POST_SELECTABLE_GROUP_STUDENTS', 'GET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID']),
            ...mapMutations('selectableDisciplines', ['SET_SELECTABLE_GROUP_ID', 'SET_EDUCATION_DISCIPLINE_ID']),

            selectFlow() {
                this.isFlowSelected = true
                //this.filteredFlows = this.selectableDisciplines_form.flows.filter(i => i.selectable_flow_id == this.selectable_flow_id)
            },
            changeSelectableGroupId(value) {
                // console.log(value, "value")
                this.SET_SELECTABLE_GROUP_ID(value)
            },

            changeSelectableFlowId(e, val = 'value') {
                this.selectedGroup = null
                let value = e.target[val]
                // console.log(value, "value")
                this.selectable_flow_id = value
            },
            async postSelectableGroupStudents() {
                // console.log(this.selectedGroup, 'this.selectedGroup')
                if (this.selectedGroup) {
                    this.isFlowSelected = false
                    this.postSelectableGroupStudentsButton = true

                    const res = await this.POST_SELECTABLE_GROUP_STUDENTS(this.selectedGroup)
                    if (res.success == true) {
                        await this.GET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID(this.education_discipline_id)
                        this.$message({title: 'Сохранение', text: 'Успешно сохранен'});
                    } else {
                        this.$error({title: 'Сохранение', text: 'Произошла ошибка'})
                        this.postSelectableGroupStudentsButton = false
                    }

                }

            }


        },
        async mounted() {
            await this.SET_EDUCATION_DISCIPLINE_ID(this.education_discipline_id)
            await this.GET_SELECTABLE_STUDENT_GROUPS_BY_STUDENT_DATA_ID(this.education_discipline_id)
            if (!this.selectableDisciplines_form.selectableStudentGroupsByStudentDataId) {
                await this.GET_EDUCATION_COURSES_BY_STUDENT_ID();
            }
            this.loadingPage = false
        }
    }
</script>

<style scoped>

</style>